<template>
  <div style="background-color: white" class="version-desktop">
    <div class="d-flex logo-menu" v-if="dataShop">
      <router-link
        :to="{
          name: $route.params.shopId ? 'library' : 'library domain',
        }"
        ><div class="logo">
          <img
            v-if="logoShop"
            :src="logoShop"
            width="100%"
            height="100%"
            style="
              object-fit: contain;
              width: 200px;
              height: 50px;
              object-position: left;
            "
            @click="toggleActiveTabLibrary"
          />
        </div>
      </router-link>
      <div class="menu">
        <div class="header-listOption-menu" style="">
          <VueSlickCarousel v-bind="settingsSlick" v-if="Object.keys(dataMenu1).length > 0">
            <template #nextArrow="arrowOption">
              <div class="custom-arrow">
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>
            <template #prevArrow="arrowOption">
              <div class="custom-arrow">
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>
            <div
              class="header-listOption-menu-item link-header position-relative"
              v-for="(item, index) in dataMenu1"
              :key="index"
            >
              <!-- <router-link
                :to="`${beginUrl}${item.url}`"
                class="d-flex flex-column align-items-center mt-2"
                :class="{ 'img-menu-header': isImage(item.icon) }"
              >
                <img v-if="isImage(item.icon)" :src="item.icon" alt="" />
                <b-icon v-else :icon="`${item.icon}`" />
                <span class="text-name">{{ item.page_title }}</span>
                <span
                  v-if="item.page_title == 'カート'"
                  class="header-listOption-menu-item-cart position-absolute"
                >
                  {{ countListCart }}
                </span>
                <span
                  v-if="item.page_title == 'ニュース' && countNewUnread > 0"
                  class="header-listOption-menu-news position-absolute"
                >
                </span>
              </router-link> -->
              <div
                class="d-flex flex-column align-items-center mt-3"
                :class="{ 'img-menu-header': isImage(item.icon) }"
                :name="returnName(item)"
                @click="returnPage(item)"
              >
                <img v-if="isImage(item.icon)" :src="item.icon" alt="" />
                <b-icon v-else :icon="`${item.icon}`" />
                <span
                  class="text-name"
                  v-if="item.page_title == '直接URLを入力する'"
                >
                  {{ item.title_other }}</span
                >
                <span class="text-name" v-else>{{ item.page_title }}</span>
                <span
                  v-if="item.page_title == 'カート'"
                  class="header-listOption-menu-item-cart position-absolute"
                >
                  {{ countListCart }}
                </span>
                <span
                  v-if="item.page_title == 'ニュース' && countNewUnread > 0"
                  class="header-listOption-menu-news position-absolute"
                >
                </span>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <!--<b-col class="col-1 version-desktop">
        <img
          @click="toggleSidebar"
          class="header-listOption-bar btnTabs"
          :src="`${icon_menu}`"
          alt=""
        />
      </b-col>-->
      <div
        :style="{
          background: detailDesignSetting.header_background
            ? detailDesignSetting.header_background
            : 'white',
        }"
        class="menu-background"
      ></div>
    </div>
    <div class="d-flex filter-tab">
      <div class="filter">
        <div class="header-search d-flex align-items-center">
          <form @submit.prevent="searchContent" style="width: 100%">
            <b-form-input
              class="search-input"
              placeholder="商品名・目次で検索・著者名・カテゴリ名"
              style="border: 1px solid #939393"
              v-model="keyword"
            />
          </form>
          <div class="icon-filter">
            <b-icon
              class="search-icon"
              icon="search"
              style="color: black"
              @click="searchContent()"
            />
          </div>
        </div>
      </div>
      <div class="header-tab d-flex align-items-end">
        <router-link
          :to="{
            name: $route.params.shopId ? 'library' : 'library domain',
          }"
        >
          <div
            class="custom-tab d-flex align-items-center justify-content-center"
            :class="{ active: isActiveTabLibrary }"
            @click="toggleActiveTabLibrary"
            style="height: 48.75px"
          >
            コンテンツ
          </div>
        </router-link>
        <router-link
          :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
        >
          <div
            class="custom-tab d-flex align-items-center justify-content-center"
            :class="{ active: isActiveTabStore }"
            @click="toggleActiveTabStore"
            style="height: 48.75px"
          >
            ショップ
          </div>
        </router-link>
      </div>
      <div
        :style="{
          background: detailDesignSetting.menu_background
            ? detailDesignSetting.menu_background
            : 'white',
        }"
        class="background-header"
      ></div>
    </div>
    <div v-if="isShowModal === true && is_login === true">
      <PopUp @show_modal="hideModalPop"></PopUp>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import PopUp from "@/views/default/PopUp";
import { Routes } from "../../utils/routes";
import icon_menu from "@/assets/img/iconmenu.png";
import icon_logout from "@/assets/img/menu_logout.png";
import no_image from "@/assets/img/logo_default.png";

export default {
  name: "Header",
  data() {
    return {
      icon_menu: icon_menu,
      icon_logout: icon_logout,
      settingsSlick: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
      },
      settingsSlickSP: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
      },
      dataMenu: [
        { id: 1, icon: "person-fill", name: "著者" },
        { id: 2, icon: "book", name: "Name 2" },
        { id: 3, icon: "cart", name: "カート" },
        { id: 4, icon: "person-fill", name: "Name 4" },
        { id: 5, icon: "book", name: "Name 5" },
      ],
      isActive: false,
      showModal: false,
      isActiveTabStore: false,
      isActiveTabLibrary: false,
      isLoading: false,
      dataMenu1: [],
      countListCart: 0,
      isShowModal: true,
      beginUrl: this.$route.params.shopId
        ? "/" + Routes.SHOP + "/" + this.$route.params.shopId
        : "",
      keyword: "",
      dataShop: [],
      logoShop: null,
      shop_id: "",
      is_login:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
      inquiry_URL: "",
      shopId: localStorage.getItem(Constants.SHOP_ID),
      time_start: new Date().getTime(),
      time_spent: 0,
      uiNote: 0,
    };
  },
  components: { VueSlickCarousel, PopUp },
  async created() {
    if (
      this.is_login &&
      !this.$route.name.includes("paymentBuy") &&
      !this.$route.name.includes("confirm_payment")
    ) {
      await this.getListSettingShopUser({
        domain: Constants.DOMAIN,
        shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
      });
      await this.showDesignSettingUser({
        shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
      });
      let bdRequest = {
        domain: Constants.DOMAIN,
        shop_id: this.$route.params.shopId || null,
      };
      let response = await this.$store.dispatch("getShopById", bdRequest);
      this.shop_id = response.data.id;
      const shop_id = this.shop_id;
      this.getListCart({
        shop_id,
      });
      this.checkUrl();
      this.getListSettingPageHeader(this.shop_id);
      if (localStorage.getItem(Constants.TOKEN_USER)) {
        const req = {
          shop_id: this.shop_id,
          user_id: localStorage.getItem(Constants.USER_ID),
        };
        this.getListSlagUser(req);
      }
      const formData = {
        shop_id: this.shop_id,
        user_type: localStorage.getItem(Constants.USER_TYPE_USER),
      };
      this.getProfile(formData);
      const request = {
        shop_id: this.shop_id,
      };
      this.getUnreadNew(request);
    }
  },
  computed: {
    ...mapGetters([
      "listCart",
      "success",
      "error",
      "amountCart",
      "listSettingPageHeader",
      "ListSettingShop",
      "listSlagUser",
      "userProfile",
      "countNewUnread",
      "contentLibraryById",
      "detailShop",
      "isShowLayout",
      "detailDesignSetting",
    ]),
  },
  watch: {
    $route() {
      if (
        this.$route.params.idContent &&
        this.$route.params.idChapter &&
        this.$route.params.idPage
      ) {
        this.getContentLibraryById({
          id: this.$route.params.idContent,
          shop_id: this.$route.params.shopId,
        });
      } else {
        this.uiNote = 0;
      }
      this.checkUrl();
    },
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop = Constants.URL_BE + "/" + this.dataShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
        this.inquiry_URL = this.dataShop[0].url_qa;
      }
    },
    listSettingPageHeader() {
      this.dataMenu1 = JSON.parse(JSON.stringify(this.listSettingPageHeader));
      if (this.dataMenu1.length > 5) {
        this.settingsSlick.arrows = true;
        this.settingsSlickSP.arrows = true;
      }
      if (window.matchMedia("only screen and (max-width: 767px)").matches) {
        this.settingsSlickSP.slidesToShow = 4;
      }
    },
    listCart() {
      this.countListCart = this.listCart.length;
      // this.listCart.forEach((element) => {
      //   // element.list_cart.forEach(() => {
      //     this.countListCart += 1;
      //   // });
      // });
    },
    listSlagUser() {
      const list = [];
      if (this.listSlagUser) {
        this.listSlagUser.forEach((item) => {
          list.push(item.id);
        });
        localStorage.setItem(Constants.LIST_SLAG, list);
      }
    },
    contentLibraryById() {
      if (this.$route.params.idPage && this.$route.params.idContent) {
        if (this.contentLibraryById && this.contentLibraryById.length > 0) {
          this.uiNote = this.contentLibraryById[0].design_settings;
        }
      } else {
        this.uiNote = 0;
      }
    },
  },
  methods: {
    ...mapActions({
      getListSettingShopUser: "getListSettingShopUser",
      getUnreadNew: "getUnreadNew",
      logout: "logout",
      getListCart: "getListCart",
      getListSettingPageHeader: "getListSettingPageHeader",
      getListSlagUser: "getListSlagUser",
      getProfile: "getProfile",
      getContentLibraryById: "getContentLibraryById",
      showDesignSettingUser: "showDesignSettingUser",
    }),
    searchContent() {
      if (this.keyword) {
        const self = this;
        const keyword = self.keyword;
        if (self.$route.params.shopId) {
          self.$router
            .push({
              name: self.isActiveTabLibrary ? "library" : "store",
              params: {
                shopId: self.shop_id,
              },
              query: {
                keyword: self.keyword,
              },
            })
            .catch(() => {
              self.$router
                .push({
                  name: "store",
                })
                .then(() => {
                  self.$router.push({
                    name: self.isActiveTabLibrary ? "library" : "store",
                    params: {
                      shopId: self.shop_id,
                    },
                    query: {
                      keyword: keyword,
                    },
                  });
                });
            });
        } else {
          self.$router
            .push({
              name: self.isActiveTabLibrary ? "library domain" : "store domain",
              query: {
                keyword: self.keyword,
              },
            })
            .catch(() => {
              self.$router
                .push({
                  name: "store domain",
                })
                .then(() => {
                  self.$router.push({
                    name: self.isActiveTabLibrary
                      ? "library domain"
                      : "store domain",
                    query: {
                      keyword: keyword,
                    },
                  });
                });
            });
        }
        self.keyword = "";
      }
    },
    toggleNav() {
      this.isActive = !this.isActive;
      this.$emit("toggleNav", this.isActive);
    },
    showModalMenu() {
      this.showModal = !this.showModal;
    },
    logoutBtn() {
      this.isLoading = true;
      this.logDataAction();
      const shopId = localStorage.getItem(Constants.SHOP_ID);
      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      api
        .post(`/${Urls.LOGOUT}`)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.$toasted.success(data.message).goAway(5000);
            this.$store.commit("set", ["success", false]);
            this.$store.commit("set", ["message", ""]);
            if (localStorage.getItem(Constants.TOKEN_USER)) {
              localStorage.removeItem(Constants.USER_TYPE_USER);
              localStorage.removeItem(Constants.TOKEN_USER);
              localStorage.removeItem(Constants.NORMAL_USER_INFO);
              localStorage.removeItem(Constants.LIST_SLAG);
            } else {
              localStorage.removeItem(Constants.USER_TYPE_ADMIN);
              localStorage.removeItem(Constants.TOKEN_ADMIN);
              localStorage.removeItem(Constants.ADMIN_USER_INFO);
            }
            if (this.$route.params.shopId) {
              this.$router.push({
                name: "login shop",
                params: { shopId: shopId },
              });
            } else {
              this.$router.push({
                name: "login shop domain",
              });
            }
            this.isLoading = false;
          } else {
            this.$toasted.error(data.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toasted.error(error.message);
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
        });
    },
    toggleSidebar() {
      this.isActive = !this.isActive;
      this.$emit("toggleSidebar", this.isActive);
    },
    goToMyPage() {
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "my profile",
          params: { shopId: this.shopId },
        });
      } else {
        this.$router.push({
          name: "my profile domain",
        });
      }
    },
    goToNews() {
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "list new user",
          params: { shopId: this.shopId },
        });
      } else {
        this.$router.push({
          name: "list new user domain",
        });
      }
    },
    goToListCart() {
      // const { params } = this.$route;
      // const { shopId } = params;
      // TEST
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "default cart",
          params: { shopId: this.shopId },
        });
      } else {
        this.$router.push({
          name: "default cart domain",
        });
      }
    },
    goToStore() {
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      this.$router.push({
        name: this.$route.params.shopId ? "store" : "store domain",
      });
    },
    goToLibrary() {
      this.isActiveTabLibrary = true;
      this.isActiveTabStore = false;
      this.$router.push({
        name: this.$route.params.shopId ? "library" : "library domain",
      });
    },
    toggleActiveTabStore() {
      this.$store.commit("set", ["isShowLayout", false]);
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      this.$emit("active", this.isActiveTabStore);
    },
    toggleActiveTabLibrary() {
      this.$store.commit("set", ["isShowLayout", false]);
      this.isActiveTabLibrary = true;
      this.isActiveTabStore = false;
      this.$emit("active", this.isActiveTabLibrary);
    },
    goToListAuthor() {
      const { params } = this.$route;
      const { shopId } = params;
      this.isActiveTabLibrary = false;
      this.isActiveTabStore = true;
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "ListAuthorStore",
          params: { shopId: shopId },
        });
      } else {
        this.$router.push({
          name: "ListAuthorStore domain",
        });
      }
    },
    checkUrl() {
      const urlPage = window.location.pathname;
      const urlLibrary = this.$route.name.includes("library");
      const urlStore = urlPage.search("store");
      if (urlLibrary) {
        this.isActiveTabLibrary = true;
        this.isActiveTabStore = false;
      } else if (urlStore !== -1) {
        this.isActiveTabStore = true;
        this.isActiveTabLibrary = false;
      } else if (!urlLibrary && urlStore === -1) {
        this.isActiveTabStore = false;
        this.isActiveTabLibrary = true;
      }
    },
    hideModalPop(e) {
      this.isShowModal = e;
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(url);
    },
    returnPage(item) {
      this.$store.commit("set", ["isShowLayout", false]);
      if (item.page_title == "お問い合わせ") {
        // window.location.href this.inquiry_URL;
        if (this.inquiry_URL) {
          window.location.href = this.inquiry_URL;
        } else {
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "create inquiry user",
              params: { shopId: this.shopId },
            });
          } else {
            this.$router.push({
              name: "create inquiry user domain",
            });
          }
        }
      } else {
        // return this.beginUrl + item.url;
        // window.location.href = this.beginUrl + item.url;
        // location.assign(location.origin + this.beginUrl + item.url);
        // this.$router.push({
        //   path: this.beginUrl + item.url,
        // });
        if (item.type == 8 || item.type == 9) {
          this.$store.commit("set", ["isShowLayout", true]);
          this.$store.commit("set", ["idModalFixed", item.fixed_page_id]);
          return;
        }
        if (this.$router.history.current.path == item.url) return;
        if (item.url.includes("fixed-page")) {
          window.location.href =
            location.protocol +
            "//" +
            location.hostname +
            (location.port ? ":" + location.port : "") +
            item.url;
          return;
        }
        if (item.page_title == "直接URLを入力する") {
          window.location.href = item.url;
          return;
        }
        this.$router.push({
          path: this.beginUrl + item.url,
        });
      }
    },
    returnName(item) {
      if (item.fixed_page_id) {
        return "menu_fixed_" + item.fixed_page_id + "_link";
      } else {
        const name = item.url.replace("/", "_");
        return "menu" + name + "_link";
      }
    },
    logDataAction() {
      const req = {
        shop_id: this.shopId,
        title: this.$route.meta.title,
        referrer_url: window.location.origin + this.$route.path,
        url:
          window.location.origin +
          this.$router.resolve({
            name: this.shopId ? "login shop" : "login shop domain",
          }).href,
        id_button: window.document.activeElement.name,
      };
      if (req.referrer_url !== req.url) {
        this.time_spent = (new Date().getTime() - this.time_start) / 1000;
        req.time_spent_on_page = this.time_spent;
        this.time_start = new Date().getTime();
      }
      this.$store.dispatch("logActive", req);
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.img-logo-header {
  width: 150px;
  height: 79px;
  @media (max-width: 767px) {
    width: 100px;
  }
}
.sidebar {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1031;
  }
  &-menu {
    position: fixed;
    transform: translateX(100%);
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    z-index: 1031;
    display: flex;
    flex-flow: column;
    flex-shrink: 1;
    width: 380px;
    .close-sidebar-btn {
      font-size: 28px;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 20px 40px 0px 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      svg {
        cursor: pointer;
        color: #fff;
        position: absolute;
        top: 10px;
        right: 0;
      }
      .infor-user {
        font-size: 14px;
        color: white;
      }
    }
  }

  .menu-sidebar-logout {
    width: 100%;
    // height: 100px;
    // border-top: 1px solid #0176d6;
    display: flex;
    justify-content: center;
    align-items: center;
    .logout-btn {
      background-color: rgba(0, 0, 0, 0);
      padding: 10px;
      font-size: 15px;
      // cursor: pointer;
      opacity: 1;
      // border-radius: 10px;
      color: #000;
      border: none;
      &:hover {
        // background-color: rgba(0, 0, 0, 0.2);
        opacity: 0.7;
        cursor: pointer;
      }
      img {
        width: 100px;
      }
    }
  }

  &-scroll {
    // padding-right: 40px;
    padding: 20px 40px 0px 40px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f1f1f1;
      border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #c1c1c1;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
  }
  @media (max-width: 767px) {
    &-scroll {
      margin: 20% 0px 0px;
      padding: 0;
    }
  }
  &-items {
    width: 330px;
    padding: 15px 20px 15px 0px;
    &:not(:first-child) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .sidebar-link {
      text-decoration: none;
      cursor: pointer;
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .sidebar-item {
        padding: 15px 20px 15px 0px;
        font-size: 15px;
        border-radius: 10px;
        color: #fff;
        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
        svg {
          font-size: 20px;
          // opacity: 35% !important;
        }
        .sidebar-menu-item-cart {
          height: 20px;
          width: 20px;
          background: orangered;
          font-size: 12px;
          border-radius: 50%;
          text-align: center;
          top: 10%;
          left: 5%;
          // opacity: 35%;
        }
      }
    }
  }
  &.active {
    .sidebar-menu {
      transform: translateX(0%);
    }
    .backdrop {
      transform: translateX(0%);
    }
  }
  @media (max-width: 767px) {
    .sidebar {
      &-menu {
        padding: 60px 20px 0 40px;
        width: 380px;
        &-scroll {
          padding-right: 10px;
          &-items {
            width: 270px;
            font-size: 16px;
            a {
              &-item {
                font-size: 16px;
                padding: 15px;
              }
            }
          }
        }
        .close-sidebar-btn {
          font-size: 28px;
          color: #fff;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .img-logo-header {
    width: 100%;
    height: 69px;
  }
}
.slick-next {
  right: 0;
  z-index: 1;
}
.slick-prev {
  left: 0;
  z-index: 1;
}
.slick-next::before {
  color: black !important;
}
.slick-prev::before {
  color: black !important;
}
.img-menu-header {
  width: 100%;
  height: 1em;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.img-menu-header-sidebar {
  display: flex;
  width: 100%;
  height: 3em;
  img {
    width: 8%;
    height: 100%;
    object-fit: contain;
  }
}
.btnTabs {
  width: 50px;
  height: 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
}
// .custom-tab {
//   margin-bottom: 10px;
//   position: relative;
// }
// .custom-tab::after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   transform: scaleX(0);
//   height: 2px;
//   bottom: 0;
//   left: 0;
//   background-color: #f55747;
// }
// .custom-tab:hover:after {
//   transform: scaleX(1);
//   transition: transform 0.25s ease-out;
// }
// .custom-tab.active::after {
//   transform: scaleX(1);
// }
.slick-track {
  padding-top: 50px;
}
// .header-listOption-menu {
//   .slick-track {
//     padding-top: 0px;
//  }

.menu-background {
  height: 62.5px;
  background-color: white;
  width: 1000vw;
  left: -1000px;
  position: absolute;
  z-index: -999999;
}
</style>
