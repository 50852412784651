<template>
  <div
    class="pre-Column"
    :style="{
      'background-image': `url(${checkBackgroundImg})`,
      'background-color': `${page && page.colorBackground}`,
      'background-repeat': returnRepeat(`${page && page.repeatBackground}`),
      'background-attachment': returnFixed(`${page && page.fixedBackground}`),
      'background-position': returnPlace(`${page && page.placeBackground}`),
      display: returnCheckView(),
    }"
  >
    <v-style> {{ page && page.css }} </v-style>
    <div class="header-content">
      <div class="container" style="min-height: 90vh">
        <div class="header-pre row">
          <img v-if="optionColumn && checkHeaderImg" :src="checkHeaderImg" />
        </div>
        <div
          class="content-pre ct-home row"
          :class="
            page &&
            (page.type_header == type_header_constant['frame'] ||
              page.type_header == type_header_constant['menu_frame'])
              ? 'content-frame'
              : ''
          "
          :style="{
            'background-image': `url(${checkArticleImg})`,
            'background-color': `${page && page.colorArticle}`,
            'background-repeat': returnRepeat(`${page && page.repeatArticle}`),
            'background-attachment': returnFixed(
              `${page && page.fixedArticle}`
            ),
            'background-position': returnPlace(`${page && page.placeArticle}`),
            '-webkit-box-shadow': returnShadow(`${page && page.colorShadow}`),
          }"
        >
          <div
            class="author-content mt-3"
            :class="
              page && page.type_header != type_header_constant['white_page']
                ? 'margin-content w-100'
                : 'w-100'
            "
          >
            <div class="card-content pt-3 row accordion-page">
              <div
                class="ql-editor previewspage"
                v-for="(value, key) in page && page.description"
                style="width: 100%"
                :key="key"
              >
                <!-- <b-collapse visible accordion="my-accordion"> -->
                <b-card-body class="px-0">
                  <div
                    class="w-100"
                    v-for="(itemBody, indexBody) in value.description.blocks"
                    :key="indexBody"
                    :class="itemBody.type === 'toggle' ? 'mt-2' : ''"
                  >
                    <!-- {{ itemBody.id }}_{{ itemBody.idChild }} -->
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'paragraph'"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div
                        v-if="itemBody.data.text"
                        v-html="itemBody.data.text"
                      ></div>
                      <div
                        v-else
                        v-html="itemBody.data.text"
                        style="height: 1.6em"
                      ></div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'tableOfContents'"
                    >
                      <h3>目次</h3>
                      <ul class="list-unstyled pl-4 listTableOfContent">
                        <li
                          v-for="(itemTOC, index) in itemBody.data.items"
                          :key="index"
                          class="pl-3"
                        >
                          <a
                            style="cursor: pointer"
                            v-scroll-to="{
                              el: '#' + itemTOC.href,
                              offset: -20,
                            }"
                            v-html="itemTOC.text"
                          ></a>
                        </li>
                      </ul>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'header'"
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <h1
                        v-if="itemBody.data.level === 1"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h1>
                      <h2
                        v-if="itemBody.data.level === 2"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h2>
                      <h3
                        v-if="itemBody.data.level === 3"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h3>
                      <h4
                        v-if="itemBody.data.level === 4"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h4>
                      <h5
                        v-if="itemBody.data.level === 5"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h5>
                      <h6
                        v-if="itemBody.data.level === 6"
                        class="mb-0"
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                        v-html="itemBody.data.text"
                      ></h6>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'list'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <ul class="w-100" style="list-style-type: decimal">
                        <li
                          v-for="(itemList, indexList) in itemBody.data.items"
                          :key="indexList"
                          class="mb-2"
                          v-html="itemList"
                        ></li>
                      </ul>
                    </div>
                    <div
                      class="item-data w-100"
                      v-if="itemBody.type === 'toggle'"
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div
                        class="w-100 d-flex align-items-center toggle-block__selector"
                        :id="itemBody.id"
                        :class="
                          itemBody.data.status === 'open'
                            ? 'toggle-open'
                            : 'toggle-close'
                        "
                      >
                        <span
                          class="icon-toggle-panel mr-2"
                          @click="toggleBlock(itemBody)"
                        >
                          <b-icon
                            :icon="
                              itemBody.data.status === 'open'
                                ? 'x-lg'
                                : 'plus-lg'
                            "
                          ></b-icon>
                        </span>
                        {{ itemBody.data.text }}
                      </div>
                    </div>
                    <!-- <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div> -->
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'code'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div v-html="itemBody.data.code"></div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100 my-3"
                      v-if="itemBody.type === 'raw'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : ''
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div v-html="itemBody.data.html"></div>
                      <!-- <code>{{ itemBody.data.html }}</code> -->
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'table'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th
                              v-for="(itemTblTh, indexTblTh) in itemBody.data
                                .content[0]"
                              :key="indexTblTh"
                              class="border-bottom-0"
                            >
                              <span class="w-100" v-html="itemTblTh"></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(itemTblTr, indexTblTr) in itemBody.data
                              .contentTbody"
                            :key="indexTblTr"
                          >
                            <td
                              v-for="(itemTblTd, indexTblTd) in itemTblTr"
                              :key="indexTblTd"
                            >
                              <span class="w-100" v-html="itemTblTd"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'checklist'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div
                        class="custom-control custom-checkbox"
                        v-for="(itemCheckbox, indexCheckbox) in itemBody.data
                          .items"
                        :key="indexCheckbox"
                      >
                        <input
                          :id="'checkbox-' + itemBody.id + indexCheckbox"
                          type="checkbox"
                          :name="'checkbox-' + itemBody.id + indexCheckbox"
                          class="custom-control-input"
                          :checked="itemCheckbox.checked"
                        />
                        <label
                          :for="'checkbox-' + itemBody.id + indexCheckbox"
                          class="custom-control-label ml-1"
                        >
                          <span v-html="itemCheckbox.text"></span>
                        </label>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'warning'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div class="w-100 bg-warning p-3 text-white">
                        <div class="w-100 font-weight-bold">
                          {{ itemBody.data.title }}
                        </div>
                        <hr />
                        <div class="w-100">
                          {{ itemBody.data.message }}
                        </div>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'quote'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <blockquote
                        class="otro-blockquote"
                        :class="
                          itemBody.data.alignment === 'left'
                            ? 'text-left'
                            : 'text-right'
                        "
                      >
                        <span>
                          {{ itemBody.data.caption }}
                        </span>
                        <span v-html="itemBody.data.text"></span>
                      </blockquote>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'headerStyle'"
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                    >
                      <div class="titleStyle" :class="itemBody.data.class">
                        <h1 class="dataInput">
                          {{ itemBody.data.text }}
                        </h1>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'boxStylle'"
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                    >
                      <div class="boxStyle" :class="itemBody.data.class">
                        <span
                          class="box-title px-2 border-0"
                          v-if="itemBody.data.title"
                          >{{ itemBody.data.title }}</span
                        >
                        <div
                          class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                          v-html="itemBody.data.text"
                        ></div>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'delimiter'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <div class="w-100 text-center ce-delimiter"></div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'image'"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <a
                        v-if="replaceText(itemBody.data.linkImage)"
                        :href="itemBody.data.linkImage"
                      >
                        <img
                          :src="itemBody.data.file.url"
                          alt=""
                          width="auto"
                        />
                      </a>
                      <img
                        v-else
                        :src="itemBody.data.file.url"
                        alt=""
                        width="auto"
                      />
                      <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'attachesPDF'"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <b-button
                        variant="primary"
                        class="mr-0"
                        name="btn-view-PDF"
                        v-on:click.prevent="
                          viewFilePDF(itemBody.data.file, itemBody.id)
                        "
                      >
                        {{ itemBody.data.title }}
                      </b-button>
                      <!-- <ViewPdf
                        v-bind:src="
                          itemBody.data.file && itemBody.data.file.url
                        "
                        v-bind:idComponent="itemBody.id"
                        v-bind:dataInfo="itemBody.data.file"
                      /> -->
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      v-if="
                        itemBody.type === 'embed' &&
                        itemBody.data.service === 'youtube'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <YoutubePlayer
                        v-bind:idElemYoutube="itemBody.id"
                        v-bind:src="itemBody.data.source"
                        v-bind:heightPlayer="itemBody.data.height"
                        v-bind:widthPlayer="itemBody.data.width"
                        v-bind:dataVideo="itemBody.data"
                      />
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      :class="
                        itemBody.idChild
                          ? checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : 'my-3'
                      "
                      v-if="
                        itemBody.type === 'embed' &&
                        itemBody.data.service === 'vimeo'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <VimeoPlayer
                        v-bind:idElemVimeo="itemBody.id"
                        v-bind:src="itemBody.data.embed"
                        v-bind:heightPlayer="itemBody.data.height"
                        v-bind:widthPlayer="itemBody.data.width"
                        v-bind:dataVideo="itemBody.data"
                      />
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100"
                      v-if="itemBody.type === 'anyButton'"
                      :class="
                        itemBody.tunes && itemBody.idChild
                          ? itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'toggle-block__item text-center'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'toggle-block__item text-right'
                            : checkIndex(itemBody, value.description.blocks)
                            ? 'toggle-block__item text-left toggle-item-last'
                            : 'toggle-block__item text-left'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'text-center my-3'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'text-right my-3'
                          : ' text-left my-3'
                      "
                      :id-child="itemBody.idChild"
                      :hidden="itemBody.showDisplay === true ? true : false"
                    >
                      <a
                        v-if="!itemBody.data.type"
                        class="anyButton"
                        :class="itemBody.data.style"
                        @click="downloadFile($event, itemBody.data)"
                      >
                        {{ itemBody.data.text }}
                      </a>
                      <div
                        class="w-100"
                        v-if="
                          itemBody.data.type !== null &&
                          (itemBody.data.style === 'roundButton' ||
                            itemBody.data.style === 'socialGlossySmooth' ||
                            itemBody.data.style === 'socialSquare')
                        "
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <a
                          v-if="itemBody.data.twitter !== null"
                          :href="itemBody.data.twitter"
                          class="btn-social-circle btn-social-circle--twitter"
                          ><i class="fab fa-twitter"></i>
                        </a>
                        <a
                          v-if="itemBody.data.facebook !== null"
                          :href="itemBody.data.facebook"
                          class="btn-social-circle btn-social-circle--facebook"
                          ><i class="fab fa-facebook-f"></i>
                        </a>
                        <a
                          v-if="itemBody.data.pocket !== null"
                          :href="itemBody.data.pocket"
                          class="btn-social-circle btn-social-circle--pocket"
                          ><i class="fab fa-get-pocket"></i>
                        </a>
                        <a
                          v-if="itemBody.data.feedly !== null"
                          :href="itemBody.data.feedly"
                          class="btn-social-circle btn-social-circle--feedly"
                          ><i class="fas fa-rss"></i
                        ></a>
                      </div>
                      <div
                        class="w-100"
                        v-if="
                          itemBody.data.type !== null &&
                          (itemBody.data.style === 'socialGiza' ||
                            itemBody.data.style === 'socialSmartPhone')
                        "
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div
                          :class="
                            itemBody.data.style === 'socialGiza'
                              ? 'btn-social-giza'
                              : 'btn-social-phone'
                          "
                        >
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza-twitter'
                                : 'btn-social-phone-twitter ml-0 mr-3'
                            "
                            v-if="itemBody.data.twitter !== null"
                          >
                            <span class="fa-stack">
                              <i
                                class="fas fa-stack-2x"
                                :class="
                                  itemBody.data.style === 'socialGiza'
                                    ? 'fa-certificate'
                                    : 'fa-tablet-alt'
                                "
                              ></i>
                              <i class="fab fa-twitter fa-stack-1x"></i>
                            </span>
                          </a>
                          <a
                            href="3535"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza-facebook'
                                : 'btn-social-phone-facebook ml-0 mr-3'
                            "
                            v-if="itemBody.data.facebook !== null"
                          >
                            <span class="fa-stack">
                              <i
                                class="fas fa-stack-2x"
                                :class="
                                  itemBody.data.style === 'socialGiza'
                                    ? 'fa-certificate'
                                    : 'fa-tablet-alt'
                                "
                              ></i>
                              <i class="fab fa-facebook-f fa-stack-1x"></i>
                            </span>
                          </a>
                          <a
                            href="5467"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza-pocket'
                                : 'btn-social-phone-pocket ml-0 mr-3'
                            "
                            v-if="itemBody.data.pocket !== null"
                          >
                            <span class="fa-stack">
                              <i
                                class="fas fa-stack-2x"
                                :class="
                                  itemBody.data.style === 'socialGiza'
                                    ? 'fa-certificate'
                                    : 'fa-tablet-alt'
                                "
                              ></i>
                              <i class="fab fa-get-pocket fa-stack-1x"></i>
                            </span>
                          </a>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'btn-social-giza-feedly'
                                : 'btn-social-phone-feedly ml-0 mr-3'
                            "
                            v-if="itemBody.data.feedly !== null"
                          >
                            <span class="fa-stack">
                              <i
                                class="fas fa-stack-2x"
                                :class="
                                  itemBody.data.style === 'socialGiza'
                                    ? 'fa-certificate'
                                    : 'fa-tablet-alt'
                                "
                              ></i>
                              <i class="fas fa-rss fa-stack-1x"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        class="w-100"
                        v-if="
                          itemBody.data.type !== null &&
                          (itemBody.data.style === 'socialFlat' ||
                            itemBody.data.style === 'socialIsometric')
                        "
                        :class="
                          itemBody.tunes && itemBody.idChild
                            ? itemBody.tunes.anyTuneName.alignment === 'center'
                              ? 'toggle-block__item text-center'
                              : itemBody.tunes &&
                                itemBody.tunes.anyTuneName.alignment === 'right'
                              ? 'toggle-block__item text-right'
                              : checkIndex(itemBody, value.description.blocks)
                              ? 'toggle-block__item text-left toggle-item-last'
                              : 'toggle-block__item text-left'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'center'
                            ? 'text-center my-3'
                            : itemBody.tunes &&
                              itemBody.tunes.anyTuneName.alignment === 'right'
                            ? 'text-right my-3'
                            : ' text-left my-3'
                        "
                      >
                        <div>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat'
                                : 'btn-social-isometric'
                            "
                          >
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                                  : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                              "
                            >
                              <i class="fab fa-twitter"></i>
                            </span>
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-text'
                                  : 'btn-social-isometric-text'
                              "
                              >TWEET</span
                            >
                          </a>
                        </div>
                        <div>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat'
                                : 'btn-social-isometric'
                            "
                          >
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                                  : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                              "
                            >
                              <i class="fab fa-facebook"></i>
                            </span>
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-text'
                                  : 'btn-social-isometric-text'
                              "
                              >TWEET</span
                            >
                          </a>
                        </div>
                        <div>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat'
                                : 'btn-social-isometric'
                            "
                          >
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                                  : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                              "
                            >
                              <i class="fab fa-get-pocket"></i>
                            </span>
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-text'
                                  : 'btn-social-isometric-text'
                              "
                              >TWEET</span
                            >
                          </a>
                        </div>
                        <div>
                          <a
                            href="1"
                            :class="
                              itemBody.data.style === 'socialFlat'
                                ? 'btn-social-flat'
                                : 'btn-social-isometric'
                            "
                          >
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                                  : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                              "
                            >
                              <i class="fas fa-rss"></i>
                            </span>
                            <span
                              :class="
                                itemBody.data.style === 'socialFlat'
                                  ? 'btn-social-flat-text'
                                  : 'btn-social-isometric-text'
                              "
                              >TWEET</span
                            >
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      :id="itemBody.id"
                      class="item-data w-100 my-3"
                      v-if="itemBody.type === 'attachesFile'"
                    >
                      <div class="w-100 d-flex box-file-upload">
                        <div
                          class="iconFile"
                          :data-extension="itemBody.data.file.extension"
                          :style="{ color: itemBody.data.file.color }"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="40"
                          >
                            <path
                              d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                            />
                          </svg>
                        </div>
                        <div class="fileName">
                          <div class="w-100">{{ itemBody.data.file.name }}</div>
                          <div
                            class="text-muted w-100"
                            v-html="convertSize(itemBody.data.file.size)"
                          ></div>
                        </div>
                        <div class="iconDowload ml-auto">
                          <a
                            class="iconDowload"
                            v-bind:download="itemBody.data.file.url"
                            rel="nofollow noindex noreferrer"
                            @click="
                              downloadFileUpload($event, itemBody.data.file)
                            "
                          >
                            <i class="fas fa-cloud-download-alt"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
                <!-- </b-collapse> -->
              </div>
            </div>
            <div
              class="card-content pt-3 row align-items-center justify-content-center"
            >
              <button
                v-on:click="returnPage()"
                class="float-right button-back-dashboard back-btn"
              >
                戻る
              </button>
            </div>
          </div>
        </div>
        <b-modal
          id="modal-detail-pdf"
          hide-footer
          v-model="modalShow"
          modal-class="modal-fullscreen"
        >
          <ViewPdf
            v-bind:src="filePDFView && filePDFView.url"
            v-bind:idComponent="filePDFView && filePDFView.idComponent"
            v-bind:dataInfo="filePDFView"
          />
        </b-modal>
      </div>
    </div>
    <!-- <div v-if="this.$route.fullPath.includes('fixed-page/pre_column_page')">
      <Footer />
    </div> -->
  </div>
</template>

<script>
// import Footer from "../../components/default/Footer";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { Constants } from "../../utils/constants";
import ViewPdf from "../../components/viewPdf/index.vue";
// import ViewPdf from "../../components/viewPdf/pdfView.vue";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import { mapGetters } from "vuex";
import Vue from "vue";

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

function initialState() {
  return {
    page: localStorage.getItem(Constants.PAGE_PREVIEW)
      ? JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW))
      : null,
    optionColumn: localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)
      ? JSON.parse(localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE))
      : null,
    type_header_constant: Constants.HEADER_FIXED_PAGE_TYPE,
    filePDFView: null,
    modalShow: false,
  };
}
export default {
  name: "previewColumnPage",
  components: {
    // Footer,
    ViewPdf,
    YoutubePlayer,
    VimeoPlayer,
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters([
      "checkHeaderImg",
      "checkBackgroundImg",
      "checkArticleImg",
      "isShowLayout",
      "isShowMenu",
    ]),
  },
  created() {
    if (localStorage.getItem(Constants.PAGE_PREVIEW)) {
      this.page = JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW));
      this.page.description.map((item) => {
        const blocksData = item.description.blocks;
        blocksData.map((itemBlock) => {
          if (itemBlock.type === "table") {
            itemBlock.data.contentTbody = itemBlock.data.content.filter(
              (itemBlock, index) => index > 0
            );
            return itemBlock;
          }
          if (itemBlock.type === "toggle") {
            itemBlock.data.status = "closed";
            const indexBlockToggle = blocksData.indexOf(itemBlock);
            if (itemBlock.data.child.length >= itemBlock.data.items) return;
            for (let i = 1; i <= itemBlock.data.items; i++) {
              itemBlock.data.child.push(blocksData[indexBlockToggle + i]);
              if (itemBlock.data.status === "closed")
                blocksData[indexBlockToggle + i].showDisplay = true;
              blocksData[indexBlockToggle + i].idChild = itemBlock.id;
            }
          }
        });
      });
      if (
        this.page.type_header ===
          Constants.HEADER_FIXED_PAGE_TYPE["menu_frame"] ||
        this.page.type_header ===
          Constants.HEADER_FIXED_PAGE_TYPE["menu_no_frame"]
      ) {
        this.$store.commit("set", ["isShowMenu", ""]);
      } else {
        this.$store.commit("set", ["isShowMenu", "none"]);
      }
    }
    this.$store.commit("set", ["isShowLayout", true]);
  },
  mounted() {
    if (this.page) {
      let externalScript = document.createElement("script");
      externalScript.innerHTML = this.page.js;
      document.head.appendChild(externalScript);
      if (this.$route.fullPath.includes("admin/fixed-page/pre_column_page"))
        this.$store.commit("set", ["isShowLayout", true]);
    }
  },
  watch: {
    $route() {
      if (this.$route.fullPath.includes("admin/fixed-page/pre_column_page"))
        this.$store.commit("set", ["isShowLayout", true]);
      if (localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)) {
        this.optionColumn = JSON.parse(
          localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)
        );
      }
      if (localStorage.getItem(Constants.PAGE_PREVIEW)) {
        this.page = JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW));
        if (
          this.page.type_header ===
            Constants.HEADER_FIXED_PAGE_TYPE["menu_frame"] ||
          this.page.type_header ===
            Constants.HEADER_FIXED_PAGE_TYPE["menu_no_frame"]
        ) {
          this.$store.commit("set", ["isShowMenu", ""]);
        }
        this.page.description.map((item) => {
          const blocksData = item.description.blocks;
          blocksData.map((itemBlock) => {
            if (itemBlock.type === "table") {
              itemBlock.data.contentTbody = itemBlock.data.content.filter(
                (itemBlock, index) => index > 0
              );
              return itemBlock;
            }
            if (itemBlock.type === "toggle") {
              itemBlock.data.status = "closed";
              const indexBlockToggle = blocksData.indexOf(itemBlock);
              if (itemBlock.data.child.length >= itemBlock.data.items) return;
              for (let i = 1; i <= itemBlock.data.items; i++) {
                itemBlock.data.child.push(blocksData[indexBlockToggle + i]);
                if (itemBlock.data.status === "closed")
                  blocksData[indexBlockToggle + i].showDisplay = true;
                blocksData[indexBlockToggle + i].idChild = itemBlock.id;
              }
            }
          });
        });
      }
    },
  },
  methods: {
    returnPage() {
      if (this.page.id) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "editFixedPage"
            : "editFixedPage domain",
          params: { shopId: this.$route.params.shopId, id: this.page.id },
        });
      } else {
        this.$router.push({
          name: this.$route.params.shopId
            ? "createFixedPage"
            : "createFixedPage domain",
        });
      }
      // this.$store.commit("set", ["isShowLayout", false]);
      this.$store.commit("set", ["isShowMenu", "none"]);
      Object.assign(this.$data, initialState());
    },
    returnRepeat(type) {
      if (type == 0) {
        return "repeat";
      } else if (type == 1) {
        return "repeat-x";
      } else if (type == 2) {
        return "repeat-y";
      } else {
        return "no-repeat";
      }
    },
    returnFixed(type) {
      if (type == 0) {
        return "fixed";
      } else {
        return "scroll";
      }
    },
    returnPlace(type) {
      if (type == 0) {
        return "right";
      } else if (type == 1) {
        return "center";
      } else {
        return "left";
      }
    },
    returnShadow(color) {
      if (
        this.page &&
        this.page.type_header !=
          Constants.HEADER_FIXED_PAGE_TYPE["white_page"] &&
        this.page.type_header !=
          Constants.HEADER_FIXED_PAGE_TYPE["menu_no_frame"] &&
        this.page.shadow == 0
      ) {
        return "0 0 8px " + color;
      } else {
        return "none";
      }
    },
    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
    returnCheckView() {
      if (!this.$route.fullPath.includes("admin/fixed-page/pre_column_page")) {
        return "none";
      } else {
        return "";
      }
    },
    replaceText(str) {
      return str ? str.replaceAll("&nbsp;", "").trim() : str;
    },
    checkIndex(toggle, list) {
      var listToggle = list.filter((item) => item.idChild === toggle.idChild);
      if (listToggle.length == 1) {
        return true;
      } else {
        var indexCurrent = listToggle.findIndex(
          (item) => item.id === toggle.id
        );
        if (indexCurrent == listToggle.length - 1) return true;
        else return false;
      }
    },
    viewFilePDF(file, idComponent) {
      this.$store.commit("set", ["clickOnPDF", true]);
      file.idComponent = idComponent;
      this.filePDFView = file;
      this.modalShow = !this.modalShow;
    },
    convertSize(size) {
      if (size) {
        let sizePrefix;
        let formattedSize;

        if (Math.log10(+size) >= 6) {
          sizePrefix = "MB";
          formattedSize = size / Math.pow(2, 20);
        } else {
          sizePrefix = "KB";
          formattedSize = size / Math.pow(2, 10);
        }

        return formattedSize.toFixed(1) + sizePrefix;
      }
    },
    async downloadFile(event, data) {
      const fileType = [
        "doc",
        "docx",
        "odt",
        "pdf",
        "rtf",
        "tex",
        "txt",
        "pptx",
        "ppt",
        "mp3",
        "mp4",
        "xls",
        "html",
        "htm",
        "png",
        "jpg",
        "jpeg",
        "gif",
        "zip",
        "rar",
        "exe",
        "svg",
        "key",
        "sketch",
        "ai",
        "psd",
        "dmg",
        "json",
        "csv",
      ];
      const url = data.link;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      const typeFile = url.substring(url.lastIndexOf(".") + 1);
      const checkFile = fileType.filter((item) => item === typeFile);
      if (checkFile.length > 0) {
        fetch(url, {
          header: "Access-Control-Allow-Origin: *",
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Use the blob here...
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
          });
      } else {
        if (data.checkbox) {
          window.open(url, "_blank");
          return;
        }
        window.location = url;
      }
    },
    async downloadFileUpload(event, data) {
      const url = data.url;
      var filename = url.substring(url.lastIndexOf("/") + 1);
      fetch(url, {
        header: "Access-Control-Allow-Origin: *",
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Use the blob here...
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style lang="scss">
.ql-editor.previewspage {
  min-height: unset !important;
  border: none;
}
.pre-Column {
  .toggle-block__item {
    max-width: unset;
  }
  .ql-editor {
    img {
      max-width: 100% !important;
    }
  }
  .accordion-page .toggle-block__item:last-child {
    margin-bottom: unset;
  }
}
.content-frame {
  width: 856.3px;
  margin: 0 auto;
  .ql-editor {
    padding: 12px 60px;
    @media (max-width: 450px) {
      padding: 12px 24px;
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
.accordion-page {
  font-size: $fontSizeBase;
  font-family: $fontFamilyBase;
  line-height: $lineHeightBase;
}
</style>
