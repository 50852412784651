<template>
  <div
    @contextmenu="
      ListSettingShop[0].is_contextmenu == 1 ? handlerClickMenu($event) : ''
    "
    @copy="ListSettingShop[0].is_contextmenu == 1 ? disableCopy($event) : ''"
    :class="{ detailContent: isDisable }"
  >
    <div
      v-bind:class="{ showPreview: !isShowLayout }"
      style="min-height: 100vh; position: relative"
    >
      <div class="default-layout fixed-page">
        <Header
          :style="{
            display: isShowMenu,
          }"
        />
        <PageStatic />
        <PreviewColumn />
      </div>
      <BottomMenu
        :style="{
          display: isShowMenu,
        }"
      />
    </div>
    <div v-bind:class="{ checkPreview: isShowLayout }">
      <div class="default-layout no-fixed">
        <Header
          :style="{
            display: isShowMenu,
          }"
        />
        <Menu />
        <div
          class="content py-3"
          :style="{
            'background-color': backgroundColor(),
          }"
        >
          <router-view :key="$route.fullPath"></router-view>
          <div class="fixedBtn" style="display: block">
            <a class="pagetop" href="#" style="display: block"
              ><img :src="btn_pagetop" alt=""
            /></a>
          </div>
        </div>
        <Footer />
        <NoteAndChatSP
          v-if="isShowButton === true"
          style="margin-bottom: 80px"
        />
      </div>
      <BottomMenu
        :style="{
          display: isShowMenu,
        }"
      />
    </div>
  </div>
</template>

<script>
import Footer from "./Footer";
import Header from "./Header";
import BottomMenu from "./BottomMenu";
import Menu from "./Menu";
import { mapGetters, mapActions } from "vuex";
// import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
import btn_pagetop from "@/assets/img/btn_pagetop.png";
import NoteAndChatSP from "../../views/default/NoteAndChatSP";
import PageStatic from "../../views/default/PageStatic";
import PreviewColumn from "../../views/admin/PreviewColumn";
import { Routes } from "../../utils/routes";

export default {
  name: "Layout",
  data() {
    return {
      btn_pagetop: btn_pagetop,
      shopId: this.$route.params.shopId
        ? this.$route.params.shopId
        : localStorage.getItem(Constants.SHOP_ID),
      is_login: localStorage.getItem(Constants.TOKEN_USER) ? true : false,
      isShow: false,
      time_start: new Date().getTime(),
      time_spent: 0,
      isCall: false,
      isDisable: false,
    };
  },
  components: {
    Header,
    Footer,
    BottomMenu,
    Menu,
    NoteAndChatSP,
    PageStatic,
    PreviewColumn,
  },
  async beforeDestroy() {
    // if (
    //   !localStorage.getItem(Constants.USER_ID) ||
    //   !localStorage.getItem(Constants.SHOP_ID)
    // )
    //   return;
    // await this.deleteRealtimeActivity({
    //   user_id: localStorage.getItem(Constants.USER_ID),
    //   shop_id: localStorage.getItem(Constants.SHOP_ID),
    // });
  },
  methods: {
    ...mapActions({
      addRealtimeActivity: "addRealtimeActivity",
      deleteRealtimeActivity: "deleteRealtimeActivity",
    }),
    handlerClickMenu(e) {
      e.preventDefault();
    },
    disableCopy(e) {
      e.preventDefault();
    },
    myFunction() {
      if (window.scrollY == 0) {
        if (document.getElementsByClassName("fixedBtn")) {
          document.getElementsByClassName("fixedBtn")[0].style.display = "none";
        }
      } else {
        if (document.getElementsByClassName("fixedBtn")) {
          document.getElementsByClassName("fixedBtn")[0].style.display =
            "block";
        }
      }
    },
    logDataAction(e) {
      // fix log in case of redirect to fixed page by url, not using router
      if (e.target && e.target.href && e.target.href.includes("fixed-page")) {
        const baseUrl =
          location.protocol +
          "//" +
          location.hostname +
          (location.port ? ":" + location.port : "");
        const href = e.target.href;
        const idFixedPage = href.split("/")[href.split("/").length - 1];
        this.$store.commit("set", [
          "routerNext",
          {
            fullPath: href.replace(baseUrl, ""),
            name: href.includes("shop") ? "staticPage" : "staticPage domain",
            params: {
              id: idFixedPage,
            },
          },
        ]);
      }
      // log active
      const req = {
        shop_id: this.shopId,
        title: this.$route.meta.title,
        referrer_url:
          this.refer_url && this.refer_url != "/"
            ? window.location.origin + this.refer_url
            : window.location.href,
        url: window.location.origin + this.routerNext.fullPath,
        id_button: window.document.activeElement.name,
      };
      if (req.referrer_url !== req.url) {
        this.time_spent = (new Date().getTime() - this.time_start) / 1000;
        req.time_spent_on_page = this.time_spent;
        this.time_start = new Date().getTime();
        this.$store.commit("set", ["isShowLayout", false]);
      }
      if (
        this.routerNext.name.includes("DetailContentLibrary") ||
        this.routerNext.name.includes("content detail")
      ) {
        req.id_content = !this.routerNext.name.includes("content detail")
          ? this.routerNext.params.idContent
          : this.routerNext.params.id;
      } else if (this.routerNext.name.includes("DetailPageLibrary")) {
        req.id_page = this.routerNext.params.idPage;
        localStorage.setItem(Constants.TIME_START, this.time_start);
      } else if (this.routerNext.name.includes("staticPage")) {
        req.id_fix_page = this.routerNext.params.id;
      } else if (this.routerNext.name.includes("author detail")) {
        req.id_author = this.routerNext.params.id;
      }
      if (
        e.target.attributes.id &&
        e.target.attributes.id.value.includes("btn-show-note")
      ) {
        req.id_note = this.idNoteLog;
      }
      if (req.url.includes("confirm_payment")) {
        this.$store.commit("set", ["refer_url", req.url]);
      }
      if (
        this.routerNext.name.includes("list new user") &&
        window.document.activeElement.className == "modal-open"
      ) {
        req.id_news = this.idNewsLog;
      }
      if (this.isCall) {
        this.isCall = false;
        this.$store.dispatch("logActive", req);
      }
    },
    changeSentence(content) {
      if (content) {
        let paramStart = content.indexOf("{%");
        let paramEnd = content.indexOf("%}");
        let sentences = JSON.parse(localStorage.getItem(Constants.SENTENCE));
        if (paramStart >= 0 && paramEnd >= 0 && sentences) {
          let param = content.slice(paramStart + 2, paramEnd);
          if (Object.prototype.hasOwnProperty.call(sentences, param)) {
            content = content.replace("{%" + param + "%}", sentences[param]);
          }
        }
      }
      return content;
    },
    backgroundColor() {
      let routeName = this.$route.name;
      if (routeName == "library domain" || routeName == "library") {
        return this.detailDesignSetting.body_background;
      } else if (
        routeName == "DetailContentLibrary domain" ||
        routeName == "DetailContentLibrary"
      ) {
        return this.detailDesignSetting.table_content_background;
      } else if (
        routeName == "DetailPageLibrary domain" ||
        routeName == "DetailPageLibrary"
      ) {
        return this.detailDesignSetting.content_background;
      } else if (routeName == "store domain" || routeName == "store") {
        return this.detailDesignSetting.shop_background;
      } else if (
        routeName == "content detail domain" ||
        routeName == "content detail"
      ) {
        return this.detailDesignSetting.content_detail_background;
      } else {
        return "white";
      }
    },
  },
  async created() {
    if (
      this.is_login &&
      localStorage.getItem(Constants.USER_ID) &&
      localStorage.getItem(Constants.SHOP_ID)
    ) {
      this.addRealtimeActivity({
        user_id: localStorage.getItem(Constants.USER_ID),
        url: location.href,
        shop_id: localStorage.getItem(Constants.SHOP_ID),
      });
    }
    const selft = this;
    window.addEventListener("focus", function () {
      setTimeout(function () {
        if (location.href.includes("admin")) return;
        selft.addRealtimeActivity({
          user_id: localStorage.getItem(Constants.USER_ID),
          url: location.href,
          shop_id: localStorage.getItem(Constants.SHOP_ID),
        });
      }, 300);
    });

    // window.addEventListener("blur", async function () {
    //   await selft.deleteRealtimeActivity({
    //     user_id: localStorage.getItem(Constants.USER_ID),
    //     shop_id: localStorage.getItem(Constants.SHOP_ID),
    //   });
    // });
    // window.addEventListener("beforeunload", async function () {
    //   await selft.deleteRealtimeActivity({
    //     user_id: localStorage.getItem(Constants.USER_ID),
    //     shop_id: localStorage.getItem(Constants.SHOP_ID),
    //   });
    // });
    this.$root.$refs.baseDefault = this;

    if (this.$route.fullPath.includes("content"))
      this.$store.commit("set", ["type", 2]);
    // let request = {
    //   domain: Constants.DOMAIN,
    //   shop_id: this.$route.params.shopId || null,
    // };
    // this.getShopById(request);
    window.addEventListener("scroll", this.myFunction);
    // if (this.$route.fullPath.includes("fixed-page/")) {
    //   this.isShow = this.isShowLayout;
    // } else {
    //   this.isShow = false;
    // }
    if (localStorage.getItem(Constants.TOKEN_USER)) {
      const self = this;
      window.addEventListener("click", function (e) {
        if (
          !self.$route.path.includes("/login") &&
          !self.$route.fullPath.includes(Constants.ADMIN)
        ) {
          if (
            (e.target.name &&
              !e.target.name.includes("add_content_") &&
              !e.target.name.includes("point_purchase_button") &&
              !e.target.name.includes("btn-view-PDF")) ||
            (e.target.attributes &&
              e.target.attributes.name &&
              e.target.attributes.name.nodeValue !== "menu_logout_link" &&
              e.target.attributes.name.nodeValue !== "btn-view-PDF") ||
            !e.target.name
          ) {
            self.isCall = true;
            if (
              self.$route.name.includes("DetailPageLibrary") ||
              self.$route.name.includes("staticPage")
            ) {
              if (!self.clickOnPDF) self.logDataAction(e);
            } else self.logDataAction(e);
          }
        }
      });
    }

    let shopId = localStorage.getItem(Constants.SHOP_ID);
    let req = {
      shop_id: shopId,
    };
    let resp = await this.$store.dispatch("checkSentence", req);
    localStorage.setItem(Constants.SENTENCE, JSON.stringify(resp.data));
  },
  computed: {
    ...mapGetters([
      "type",
      "isShowButton",
      "refer_url",
      "isShowLayout",
      "isShowMenu",
      "idNoteLog",
      "idNewsLog",
      "routerNext",
      "clickOnPDF",
      "ListSettingShop",
      "detailDesignSetting",
    ]),
  },
  watch: {
    $route() {
      if (this.is_login) {
        this.addRealtimeActivity({
          user_id: localStorage.getItem(Constants.USER_ID),
          url: location.href,
          shop_id: localStorage.getItem(Constants.SHOP_ID),
        });
      }
      this.time_spent = 0;
      if (
        !this.$route.fullPath.includes("fixed-page/") &&
        !this.$route.fullPath.includes("confirm_payment")
      ) {
        localStorage.removeItem(Constants.INFOR_ORDER);
      }
      // if (this.$route.fullPath.includes("fixed-page/")) {
      //   this.isShow = this.isShowLayout;
      // } else {
      //   this.$store.commit("set", ["isShowLayout", false]);
      //   this.isShow = false;
      // }
    },
    ListSettingShop() {
      this.isDisable = this.ListSettingShop[0].is_contextmenu;
    },
  },
  mounted() {
    if (
      window.document.referrer.includes("/page") &&
      (this.refer_url == "/" || this.refer_url.includes(`${Routes.BUY}`))
    ) {
      this.time_spent =
        (new Date().getTime() - localStorage.getItem(Constants.TIME_START)) /
        1000;
      const req = {
        shop_id: this.shopId,
        title: this.$route.meta.title,
        referrer_url: window.document.referrer,
        url: window.location.origin + this.routerNext.fullPath,
        time_spent_on_page: this.time_spent,
      };
      if (
        this.routerNext.name.includes("DetailContentLibrary") ||
        this.routerNext.name.includes("content detail")
      ) {
        req.id_content = !this.routerNext.name.includes("content detail")
          ? this.routerNext.params.idContent
          : this.routerNext.params.id;
      } else if (this.routerNext.name.includes("DetailPageLibrary")) {
        req.id_page = this.routerNext.params.idPage;
        localStorage.setItem(Constants.TIME_START, this.time_start);
      } else if (this.routerNext.name.includes("staticPage")) {
        req.id_fix_page = this.routerNext.params.id;
      } else if (
        this.routerNext.name.includes("paymentBuy") ||
        this.routerNext.name.includes("Before Login")
      ) {
        var url = window.document.referrer;
        var parameUrl = url.split("/")[url.split("/").length - 1];
        var idPage = parameUrl.replace(`${Routes.PAGE}=`, "")[0];
        req.id_page = idPage;
      }
      this.$store.dispatch("logActive", req);
    }
    this.time_spent = 0;
    this.time_start = new Date().getTime();
    if (!this.$route.fullPath.includes("admin/fixed-page/pre_column_page")) {
      this.$store.commit("set", ["isShowLayout", false]);
    } else {
      this.$store.commit("set", ["isShowLayout", true]);
    }
    if (
      !this.$route.fullPath.includes("fixed-page/") &&
      !this.$route.fullPath.includes("confirm_payment")
    ) {
      localStorage.removeItem(Constants.INFOR_ORDER);
    }
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "../../views/default/Default.scss";
@import "../../views/default/Responsive.scss";
.showPreview {
  display: none;
}
.checkPreview {
  display: none;
}
.fixedBtn {
  position: fixed;
  bottom: 70px;
  right: 10px;
  left: 85%;
  width: 40px;
  height: 90px;
  max-width: 100%;
  margin: auto;
  z-index: 1000;
  display: none;
}
.fixedBtn a {
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  float: right;
  clear: both;
}
.fixedBtn img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.detailContent {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
</style>
