var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ detailContent: _vm.isDisable },on:{"contextmenu":function($event){_vm.ListSettingShop[0].is_contextmenu == 1 ? _vm.handlerClickMenu($event) : ''},"copy":function($event){_vm.ListSettingShop[0].is_contextmenu == 1 ? _vm.disableCopy($event) : ''}}},[_c('div',{class:{ showPreview: !_vm.isShowLayout },staticStyle:{"min-height":"100vh","position":"relative"}},[_c('div',{staticClass:"default-layout fixed-page"},[_c('Header',{style:({
          display: _vm.isShowMenu,
        })}),_c('PageStatic'),_c('PreviewColumn')],1),_c('BottomMenu',{style:({
        display: _vm.isShowMenu,
      })})],1),_c('div',{class:{ checkPreview: _vm.isShowLayout }},[_c('div',{staticClass:"default-layout no-fixed"},[_c('Header',{style:({
          display: _vm.isShowMenu,
        })}),_c('Menu'),_c('div',{staticClass:"content py-3",style:({
          'background-color': _vm.backgroundColor(),
        })},[_c('router-view',{key:_vm.$route.fullPath}),_c('div',{staticClass:"fixedBtn",staticStyle:{"display":"block"}},[_c('a',{staticClass:"pagetop",staticStyle:{"display":"block"},attrs:{"href":"#"}},[_c('img',{attrs:{"src":_vm.btn_pagetop,"alt":""}})])])],1),_c('Footer'),(_vm.isShowButton === true)?_c('NoteAndChatSP',{staticStyle:{"margin-bottom":"80px"}}):_vm._e()],1),_c('BottomMenu',{style:({
        display: _vm.isShowMenu,
      })})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }