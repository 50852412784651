var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version-desktop",staticStyle:{"background-color":"white"}},[(_vm.dataShop)?_c('div',{staticClass:"d-flex logo-menu"},[_c('router-link',{attrs:{"to":{
        name: _vm.$route.params.shopId ? 'library' : 'library domain',
      }}},[_c('div',{staticClass:"logo"},[(_vm.logoShop)?_c('img',{staticStyle:{"object-fit":"contain","width":"200px","height":"50px","object-position":"left"},attrs:{"src":_vm.logoShop,"width":"100%","height":"100%"},on:{"click":_vm.toggleActiveTabLibrary}}):_vm._e()])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"header-listOption-menu"},[(Object.keys(_vm.dataMenu1).length > 0)?_c('VueSlickCarousel',_vm._b({scopedSlots:_vm._u([{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}},{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}}],null,false,3004074879)},'VueSlickCarousel',_vm.settingsSlick,false),_vm._l((_vm.dataMenu1),function(item,index){return _c('div',{key:index,staticClass:"header-listOption-menu-item link-header position-relative"},[_c('div',{staticClass:"d-flex flex-column align-items-center mt-3",class:{ 'img-menu-header': _vm.isImage(item.icon) },attrs:{"name":_vm.returnName(item)},on:{"click":function($event){return _vm.returnPage(item)}}},[(_vm.isImage(item.icon))?_c('img',{attrs:{"src":item.icon,"alt":""}}):_c('b-icon',{attrs:{"icon":`${item.icon}`}}),(item.page_title == '直接URLを入力する')?_c('span',{staticClass:"text-name"},[_vm._v(" "+_vm._s(item.title_other))]):_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(item.page_title))]),(item.page_title == 'カート')?_c('span',{staticClass:"header-listOption-menu-item-cart position-absolute"},[_vm._v(" "+_vm._s(_vm.countListCart)+" ")]):_vm._e(),(item.page_title == 'ニュース' && _vm.countNewUnread > 0)?_c('span',{staticClass:"header-listOption-menu-news position-absolute"}):_vm._e()],1)])}),0):_vm._e()],1)]),_c('div',{staticClass:"menu-background",style:({
        background: _vm.detailDesignSetting.header_background
          ? _vm.detailDesignSetting.header_background
          : 'white',
      })})],1):_vm._e(),_c('div',{staticClass:"d-flex filter-tab"},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"header-search d-flex align-items-center"},[_c('form',{staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.searchContent.apply(null, arguments)}}},[_c('b-form-input',{staticClass:"search-input",staticStyle:{"border":"1px solid #939393"},attrs:{"placeholder":"商品名・目次で検索・著者名・カテゴリ名"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('div',{staticClass:"icon-filter"},[_c('b-icon',{staticClass:"search-icon",staticStyle:{"color":"black"},attrs:{"icon":"search"},on:{"click":function($event){return _vm.searchContent()}}})],1)])]),_c('div',{staticClass:"header-tab d-flex align-items-end"},[_c('router-link',{attrs:{"to":{
          name: _vm.$route.params.shopId ? 'library' : 'library domain',
        }}},[_c('div',{staticClass:"custom-tab d-flex align-items-center justify-content-center",class:{ active: _vm.isActiveTabLibrary },staticStyle:{"height":"48.75px"},on:{"click":_vm.toggleActiveTabLibrary}},[_vm._v(" コンテンツ ")])]),_c('router-link',{attrs:{"to":{ name: _vm.$route.params.shopId ? 'store' : 'store domain' }}},[_c('div',{staticClass:"custom-tab d-flex align-items-center justify-content-center",class:{ active: _vm.isActiveTabStore },staticStyle:{"height":"48.75px"},on:{"click":_vm.toggleActiveTabStore}},[_vm._v(" ショップ ")])])],1),_c('div',{staticClass:"background-header",style:({
        background: _vm.detailDesignSetting.menu_background
          ? _vm.detailDesignSetting.menu_background
          : 'white',
      })})]),(_vm.isShowModal === true && _vm.is_login === true)?_c('div',[_c('PopUp',{on:{"show_modal":_vm.hideModalPop}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }